import React from 'react'
import ButtonFullWidth from './button-full'

export default ({ path, title }) => (
  <ButtonFullWidth
    to={path}
    title={title}
  >
    All posts
  </ButtonFullWidth>
)
