import React from 'react'
import styled from 'styled-components'
import { RightArrow } from 'styled-icons/boxicons-solid/RightArrow'
import Button from './button'
import FlexCenter from './styles/flex-center'
import Square9 from './styles/square-9'
import DisabledState from './styles/disabled-state'

const RightIcon = styled(RightArrow)`
  ${Square9};

  margin: 0 0 0 ${({ theme }) => theme.verticalRythm[6]};
`

const Btn = styled(Button)`
  ${FlexCenter};

  ${({ disabled }) => disabled ? `${DisabledState}` : ``};
`

export default ({ to, className, disabled, title }) => (
  <Btn
    to={to}
    className={className}
    disabled={disabled}
    title={title}
  >
    Next
    <RightIcon />
  </Btn>
)
