import React from 'react'
import Date from './post-publish-date'
import TimeToRead from './post-time-to-read'
import Container from './space-between'

export default ({ date, timeToRead }) => (
  <Container>
    <Date date={date} />
    <TimeToRead timeToRead={timeToRead} />
  </Container>
)
