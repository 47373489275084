import { css } from 'styled-components'

const container = ({ theme }) => `${theme.containers[0]}`
const gutter = ({ theme }) => `${theme.verticalRythm[9]}`
const precision = ({ theme }) => `${theme.breakpointPrecision}`

export default css`
  @media screen and (max-width: calc(${container} - ${precision})) {
    margin-left: -${({ theme }) => theme.verticalRythm[9]};
    margin-right: -${({ theme }) => theme.verticalRythm[9]};
  }

  @media screen and (min-width: ${container}) {
    margin-left: calc(-50vw + ${container} / 2 - ${gutter});
    margin-right: calc(-50vw + ${container} / 2 - ${gutter});
  }
`
