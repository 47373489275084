import React from 'react'
import styled from 'styled-components'

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
`

export default ({ children }) => <SpaceBetween>{children}</SpaceBetween>
