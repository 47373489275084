import React from 'react'
import { graphql } from 'gatsby'
import Image from '../components/full-bleed-img'
import Layout from '../components/layout'
import Navigation from '../components/blog-post-navigation'
import Ast from '../components/ast'
import Heading from '../components/page-heading'
import Meta from '../components/post-meta'
import Seo from '../components/seo'

export default ({ pageContext, data: { post } }) => {
  const { image, title, date, body, path } = post
  const url = path.url
  const timeToRead = body.childMarkdownRemark.timeToRead
  const htmlAst = body.childMarkdownRemark.htmlAst
  const description = body.childMarkdownRemark.excerpt
  const previous = pageContext.prev
  const next = pageContext.next

  return (
    <Layout customSeo={`true`}>
      <Seo
        title={title}
        description={description}
        banner={image.fluid.src}
        article={`true`}
        blogPost={post}
      />
      <header>
        <Heading>{title}</Heading>
        <Meta date={date} timeToRead={timeToRead} />
      </header>
      <Image fluid={image.fluid} />
      <Ast ast={htmlAst} />
      <Navigation
        path={url}
        previous={previous}
        next={next}
        title={path.label}
      />
    </Layout>
  )
}

export const PageQuery = graphql`
  query BlogPost($slug: String!) {
    post: contentfulBlogPost(slug: { eq: $slug }) {
      title
      date(formatString: "MMMM Do YYYY")
      createdAt(formatString: "YYYY-MM-DD")
      updatedAt(formatString: "YYYY-MM-DD")
      slug
      path {
        url
        label
      }
      image {
        fluid(maxWidth: 2400, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      body {
        childMarkdownRemark {
          excerpt(pruneLength: 200)
          html
          htmlAst
          timeToRead
        }
      }
    }
  }
`
