import { css } from 'styled-components'

export default css`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizes[9]};
  line-height: ${({ theme }) => theme.verticalRythm[9]};
  padding: ${({ theme }) => theme.verticalRythm[8]};
  border-radius: ${({ theme }) => theme.radii[1]};
  border: ${({ theme }) => theme.borderWidth[1]} solid ${({ theme }) => theme.colors.primary.dark};
  color: ${({ theme }) => theme.colors.primary.dark};
  background-color: ${({ theme }) => theme.colors.body};
  text-decoration: none;
  transition: border-color ${({ theme }) => theme.timing.fast} ${({ theme }) => theme.transitions.default}, color ${({ theme }) => theme.timing.fast} ${({ theme }) => theme.transitions.default};
  letter-spacing: -1px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.default};
    border-color: ${({ theme }) => theme.colors.primary.default};
  }
`
