import React from 'react'
import SpaceBetween from './space-between'
import AllPosts from './blog-post-navigation-all'
import PreviousButton from './previous-button'
import NextButton from './next-button'

export default ({ previous, next, path, title }) => (
  <nav>
    <SpaceBetween>
      {
        previous && previous.slug ?
        <PreviousButton to={`${path}/${previous.slug}`} title={previous.title}/> :
        <PreviousButton to={``} disabled />
      }
      {
        next && next.slug ?
        <NextButton to={`${path}/${next.slug}`} title={next.title} /> :
        <NextButton to={``} disabled />
      }
    </SpaceBetween>
    <AllPosts path={path} title={title} />
  </nav>
)
