import React from 'react'
import styled from 'styled-components'
import { LeftArrow } from 'styled-icons/boxicons-solid/LeftArrow'
import Button from './button'
import FlexCenter from './styles/flex-center'
import Square9 from './styles/square-9'
import DisabledState from './styles/disabled-state'

const LeftIcon = styled(LeftArrow)`
  ${Square9};

  margin: 0 ${({ theme }) => theme.verticalRythm[6]} 0 0;
`

const Btn = styled(Button)`
  ${FlexCenter};

  ${({ disabled }) => disabled ? `${DisabledState}` : ``};
`

export default ({ to, className, disabled, title }) => (
  <Btn
    to={to}
    className={className}
    disabled={disabled}
    title={title}
  >
    <LeftIcon />
    Previous
  </Btn>
)
