import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import ButtonFull from './styles/button-full'

const StyledButtonFull = styled(Link)`
  ${ButtonFull}
`

export default ({ children, className, to, title }) => (
  <StyledButtonFull
    to={to}
    className={className}
    title={title || ''}
  >
    {children}
  </StyledButtonFull>
)
