import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Button from './styles/button'

const StyledButton = styled(Link)`
  ${Button}
`

export default ({ children, className, to, title }) => (
  <StyledButton className={className} to={to} title={title || ''}>
    {children}
  </StyledButton>
)
