import React from 'react'
import styled from 'styled-components'

const Date = styled.time`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes[8]};
  line-height: ${({ theme }) => theme.verticalRythm[9]};
  margin: 0 0 ${({ theme }) => theme.verticalRythm[9]} 0;
`

export default ({ date }) => <Date>{date}</Date>
