import { css } from 'styled-components'
import Button from './button'

export default css`
  ${Button}

  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
