import React from 'react'
import styled from 'styled-components'

const TimeToRead = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes[8]};
  line-height: ${({ theme }) => theme.verticalRythm[9]};
  margin: 0 0 ${({ theme }) => theme.verticalRythm[9]} 0;
`

export default ({ timeToRead }) => <TimeToRead>{timeToRead > 1 ? `${timeToRead} minutes` : `1 minute`}</TimeToRead>
