import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import FullBleed from './styles/full-bleed'

const Image = styled(Img)`
  ${FullBleed}
`

export default props => <Image {...props} />
